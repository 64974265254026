import axios from 'axios';
import { GET_EVENTS, SELECT_EVENT } from "../types";
import { setAlert } from './alert';

export const uploadEventImage = (image) => async (dispatch) => {
    try {
        const token = localStorage.getItem('jwtToken');
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        };

        const data = new FormData();
        data.append('image', image); // Update the key to 'image' as per the Swagger documentation

        const url = `${process.env.REACT_APP_BE_URL}/images`; // Update the URL based on your Swagger documentation

        const response = await axios.post(url, data, { headers });

        if (response.data) {
            const url = response.data.data.url;
            dispatch(setAlert('Image Uploaded', 'success', 5000));
            return url;
        }
    } catch (error) {
        console.error('Error uploading image:', error);
        dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
    }
};

export const getEvents = (isAdmin) => async (dispatch) => {
    if (isAdmin) {
        const token = localStorage.getItem('jwtToken');
        const url = `${process.env.REACT_APP_BE_URL}/events?draft=true`;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        axios
            .get(url, { headers })
            .then((res) => {
                dispatch({ type: GET_EVENTS, payload: res?.data?.data });
            })
            .catch((error) => {
                dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
            });
    } else {
        const url = `${process.env.REACT_APP_BE_URL}/events`;
        axios
            .get(url)
            .then((res) => {
                dispatch({ type: GET_EVENTS, payload: res?.data?.data });
            })
            .catch((error) => {
                dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
            });
    }
};

export const addEvent = (portraitImage, landscapeImage, newEvent) => async (dispatch) => {
    console.log(newEvent)
    try {
        const portraitImageUrl = portraitImage ? await dispatch(uploadEventImage(portraitImage)) : null;
        const landscapeImageUrl = landscapeImage ? await dispatch(uploadEventImage(landscapeImage)) : null;

        const token = localStorage.getItem('jwtToken');
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        };

        const url = `${process.env.REACT_APP_BE_URL}/events`;
        const requestData = {
            name: newEvent.name,
            description: newEvent.description,
            duration: newEvent.duration,
            date: newEvent.date,
            casts: newEvent.casts,
            location: newEvent.location,
            isDraft: newEvent.isDraft,
            slug: newEvent.slug,
            startTime: newEvent.startTime,
            totalSeats: newEvent.totalSeats,
            availableSeats: newEvent.availableSeats,
            price: newEvent.price,
            landscapeImage: landscapeImageUrl,
            portraitImage: portraitImageUrl,
        }

        const response = await axios.post(url, requestData, { headers });

        if (response.data) {
            dispatch(setAlert('Movie has been saved!', 'success', 5000));
            dispatch(getEvents(true));
        }
    } catch (error) {
        console.error('Error adding movie:', error);
        dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
    }
};

export const onSelectEvent = (event) => ({
    type: SELECT_EVENT,
    payload: event
});


